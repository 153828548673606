import React from 'react';
import Layout from 'layout';
import 'sass/theme/terms.scss';

const Tos = () => (
  <Layout metaTitle="Data Processing Agreement" canonical="/data-processing-agreement/">
    <section className="terms">
      <h1>
        LIVESESSION SOFTWARE-AS-A-SERVICE <br />
        TERMS & CONDITIONS
      </h1>
      <h2>ATTACHMENT III - PERSONAL DATA PROCESSING AGREEMENT</h2>

      <p>
        <i>This Personal Data Processing Agreement (hereinafter referred to: the „</i>
        <i>
          <strong>Agreement</strong>
        </i>
        <i>
          ”) is concluded in order to supplement the SaaS Agreement as defined in the SaaS Agreement
          between LiveSession and Client (hereinafter referred to as: the „
        </i>
        <i>
          <strong>SaaS Agreement</strong>
        </i>
        <i>”).</i>
      </p>

      <p>This Agreement is made and entered into by and between:</p>
      <p>
        <strong>LiveSession </strong>
        as defined in the Terms and Conditions, (hereinafter referred to as: the{' '}
        <strong>„Processor”</strong>)
      </p>
      <p>and</p>
      <p>
        <strong>Client</strong>, as defined in the Terms and Conditions and in the Subscription Form
        (hereinafter referred to as: the <strong>„Controller”</strong>
        ),
      </p>
      <p>
        The Controller and the Processor are hereinafter jointly referred to as the
        <strong> Parties</strong> and solely and the <strong>Party</strong>.{' '}
      </p>

      <h2>PREAMBLE</h2>
      <p>Whereas:</p>
      <ol type="i">
        <li>
          The Parties are bound by a legal relationship resulting from the conclusion of the SaaS
          Agreement, and as part of its performance, the Controller entrusted the Processor with the
          processing of personal data to the extend specified in the herby Agreement;
        </li>
        <li>
          Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on
          the protection of natural persons with regard to the processing of personal data and on
          the free movement of such data, and repealing the Directive 95/46/EC (Official Journal of
          the European Union L 119, p. 1, hereinafter referred to as the “
          <strong>Regulation”</strong> or the “<strong>GDPR”</strong>) introduced new principles and
          obligations regarding the processing of personal data;
        </li>
        <li>
          for the purpose of this Agreement, the Parties agree to determine the conditions and
          principles under which the Processor performs personal data processing operations on
          behalf of the Controller while exercising the utmost diligence and at the same time
          striving to ensure that the agreed conditions and principles fully meet the requirements
          of the Regulation and the law generally applicable on the territory of the Republic of
          Poland;
        </li>
        <li>
          the Parties to this Agreement shall take all measures to ensure the high standard of
          security of the processing of personal data and undertake to cooperate fully with the
          supervisory authority within the performance of tasks by this authority;
        </li>
        <li>
          the Processor has implemented appropriate legal, technical and organizational measures to
          ensure that the processing of personal data meets the requirements of the Regulation and
          protects the rights of the persons whose data are processed;
        </li>
      </ol>

      <p>the Parties agree to conclude this Agreement and determine the following:</p>

      <h2>
        §1
        <br />
        SUBJECT MATTER AND PURPOSE OF THE AGREEMENT
      </h2>
      <ol type="1">
        <li>
          In accordance with the conditions and principles arising from this Agreement and the SaaS
          Agreement, the Controller entrusts the Processor the processing of personal data
          (hereinafter referred to as: &quot;
          <strong>data</strong>&quot; or &quot;<strong>personal data</strong>
          &quot;).
        </li>
        <li>
          By the processing of personal data, the Parties understand in particular any operation or
          set of operations which is performed on personal data or on sets of personal data, whether
          or not by automated means, such as collection, recording, organization, structuring,
          storage, adaptation or alteration, retrieval, consultation, use, disclosure by
          transmission, dissemination or otherwise making available, alignment or combination,
          restriction, erasure or destruction.
        </li>
        <li>
          The purpose of this Agreement is to define the conditions and principles for the
          processing of personal data. At the same time, to the extend not specified in this
          Agreement, the Parties shall accept the conditions and principles for the processing of
          personal data resulting from the Regulation and other generally applicable legal acts.
        </li>
        <li>
          The Processor, taking into account the nature of the processing and the available
          information, assists the Controller to meet the obligations set out in articles 32-36 of
          the Regulation, guaranteeing at the same time the high level of security of processing of
          personal data. The Processor assists the Controller to meet the obligation to respond to
          the requests of the data subject in exercise of rights set out in Chapter III of the
          Regulation.
        </li>
      </ol>

      <h2>
        §2 <br />
        NATURE AND PURPOSE OF PROCESSING OF PERSONAL DATA, <br />
        TYPE OF DATA PROCESSED, CATEGORIES
      </h2>

      <ol type="1">
        <li>
          The nature and purpose of personal data processing results from the SaaS Agreement and the
          provisions of this Agreement. Having regard to the scope and content of the SaaS Agreement
          the Parties mutually agree:
          <ul>
            <li>
              the nature of the data processing results from the role of the Processor as Party
              providing non-exclusive SaaS (software as a service) license (right to use) according
              to the SaaS Agreement, therefore it is necessary for the Processor to have permanent
              access to all data necessary for the correct implementation of its tasks, or to data
              indicated by the Controller of personal data;
            </li>
            <li>
              the purpose of data processing is to ensure that providing non-exclusive SaaS
              (software as a service) license (right to use) by Processor complies with the
              Regulation.
            </li>
          </ul>
        </li>
        <li>
          The processing of personal data shall include, in particular, the following types of
          personal data:
          <ol type="i">
            <li>
              Ordinary data: kind of device used, IP address, ID number if any, email address, name,
              last name, contact details, geo-location, used language, online behavior and
              activities on a given website, including: clicks, mouse point moves, usage of
              particular sections of the website, searches, time spend on any activity, keystrokes,
              any visible text typed in forms (gaps) available at the website, information gathered
              throughout cookies being part of LiveSession SaaS tracking code
            </li>
          </ol>
          and other data that turn out to be necessary due to the purpose and nature of the
          implementation of the SaaS Agreement.
        </li>
        <li>
          The processing of personal data concerns the following categories of persons: visitors and
          users of Processor’s websites to which LiveSession SaaS is applied.{' '}
        </li>
        <li>
          The processing of personal data shall take place in the manner necessary and proportionate
          to the purpose and nature of the processing of personal data resulting from the SaaS
          Agreement and specified in §2 sec. 1.
        </li>
      </ol>

      <h2>
        §3 <br />
        ENTRUSTING THE PROCESSING OF PERSONAL DATA{' '}
      </h2>

      <ol type="1">
        <li>
          The Controller grants the general consent to the Processor to entrust specific data
          processing activities to other processors.
          <strong> </strong>
          (hereinafter referred to as: the
          <strong> </strong>„<strong>Sub-Processor</strong>
          ”).{' '}
        </li>
        <li>
          Entrusting the processing of personal data by the Processor to the Sub-Processors
          requires, for its validity, the conclusion of a written (including electronic) agreement
          on the sub-entrusting the processing of personal data (hereinafter referred to as: the
          &quot;<strong>Sub-entrusting agreement</strong>
          &quot;).
        </li>
        <li>
          In accordance with Sub-entrusting agreement, the Processor obliges the Sub-Processor to
          act in accordance with the principles and conditions resulting from this Agreement. In
          particular, the Sub-Processor is obliged to process personal data taking into account the
          principles and obligations resulting from the Regulation, the generally applicable
          provisions of law and this Agreement having regard to the nature and purpose of the
          processing of personal data resulting from the SaaS Agreement.{' '}
        </li>
        <li>
          The Processor shall promptly, yet not later than within 30 business days, inform the
          Controller about any instances of sub-entrusting, changing, adding or replacing any
          Sub-Processor. The obligation to inform may be performed by an update of the list of
          Sub-Processors made available on the LiveSession’s website
          https://livesession.io/subprocessors to what the Controller explicitly consents. The
          Controller may, for justified reasons, file an observation to sub-processing, changing,
          adding or replacing any of the Sub-Processors. For an avoidance of any doubt, by
          consenting to this Agreement the Controller agrees to the following, but not limited to,
          Sub-Processors: Google Ireland Limited.
        </li>
        <li>
          In the case when the observation has been filed by the Controller, the Processor is not
          entitled to entrust data to the Sub-Processor covered by the observation, and if the
          observation concerns the current Sub-Processor, entrusting the processing of personal data
          to this Sub-Processor shall immediately be terminated.{' '}
        </li>
        <li>
          In the event of concluding further Sub-entrusting agreements, the Controller retains the
          right to be informed of every case of sub-entrusting and retains the right to file the
          observation.
        </li>
        <li>
          Providing information and filing observations shall be made by means of electronic
          communication to the e-mail addresses indicated in § 13 sec. 5.
        </li>
        <li>
          The Processor declares to know and adheres to the conditions and principles of using the
          services of another Processor (Sub-Processor).
        </li>
        <li>
          The Processor is responsible for the actions and omissions of the Sub-Processor as for his
          own actions and omissions. For the avoidance of doubt, full responsibility for ensuring
          compliance of the processing of personal data by the Sub-Processor with the SaaS
          Agreement, this Agreement, Regulation and other provisions of generally applicable law
          rests with the Processor.
        </li>
        <li>
          The Processor is not allowed to entrust the Sub-Processor with the processing of personal
          data to the full extent specified in this Agreement without the prior written consent of
          the Controller.
        </li>
      </ol>

      <h2>
        §4 <br />
        ENTRUSTING PERSONAL DATA OUTSIDE OF EEA
      </h2>
      <ol type="1">
        <li>
          The Parties jointly agree that the personal data may be transferred to a country or
          international organization from outside of the European Economic Area, with the
          reservation of § 4 sec. 3.{' '}
        </li>
        <li>
          The Processor informs that it uses subcontractors, in particular Sub-Processors, who
          transfer data to natural or legal persons from countries or organizations from outside of
          the European Economic Area, with the reservation of § 4 sec. 3.{' '}
        </li>
        <li>
          The Processors may transfer the personal data, or may allow a Sub-Processor to transfer
          the personal data outside of the EEA, provided that requirements prescribed by the Article
          45 or 46 or 49 of GDPR are fulfilled.
        </li>
        <li>
          If the obligation to transfer data outside of the European Economic Area is imposed on the
          Processor by the European Union law or the law of a Member State, in such a case before
          the processing begins, the Processor informs the Controller about this legal obligation,
          unless this law prohibits revealing such information due to important public interest.
        </li>
      </ol>

      <h2>
        §5 <br />
        OBLIGATIONS OF THE PROCESSOR
      </h2>
      <ol type="1">
        <li>
          The Processor processes personal data only in accordance with the documented orders and
          instructions of the Controller.
        </li>
        <li>
          The Processor shall obtain, from persons who have been authorized to process personal
          data, written declarations to keep confidentiality of all information and data about which
          they have learned directly or indirectly in conjunction with the processing of personal
          data.
        </li>
        <li>
          The Processor ensures the protection and security of personal data and takes the necessary
          measures to protect personal data referred to in art. 32 of the Regulation.
        </li>
        <li>
          The Processor undertakes to cooperate and assist the Controller in the implementation of
          the rights of the individual set out in Chapter III of the Regulation, in particular
          through the use of appropriate technical and organizational measures to comply with a
          request of the data subject in the exercise of rights set out in Chapter III of the
          Regulation.
        </li>
        <li>
          The Processor undertakes to cooperate and assist the Controller in the implementation of
          obligations arising out from the area of personal data protection, referred to in art. 32
          - 36 of the Regulation. The abovementioned area of personal data protection covers, in
          particular, protection of personal data, reporting violations to the supervisory
          authority, notifying people affected by data breach, assessment of consequences for the
          protection of personal data and prior consultation with the supervisory authority.
        </li>
        <li>
          The Processor undertakes to provide the Controller, upon every reasonable request, all
          pieces of information necessary to ensure the lawfulness of personal data processing,
          demonstrating the Controller&quot;s compliance with the provisions of the Regulation and
          enables the Controller or the auditor authorized by the Controller to carry out audits and
          inspections of personal data processing.
        </li>
        <li>
          The Processor shall promptly inform the Controller if, in his opinion, orders or
          instructions issued to him violate the provisions of the Regulation, other provisions of
          the law of the European Union or law of the Member State regarding personal data
          protection. The Processor shall promptly inform the Controller about any doubts raised,
          under the pain of losing the right to assert claims against the Controller in this
          respect.
        </li>
        <li>
          The Processor cooperates with the appropriate authority for the protection of personal
          data in the scope of performing its tasks, as well as with other European Union bodies,
          national and local government authorities as the extent this cooperation is related to the
          protection and security of personal data.
        </li>
        <li>
          The processor undertakes to comply with the requirement to use the default privacy
          protection settings referred to in Article 25 sec. 1 of the Regulation and immediately
          informs about plans to change the way of the processing of personal data. Changes in the
          way of the processing of personal data shall not lower down the agreed level of security
          of the processing of personal data.
        </li>
        <li>
          The Processor agrees that access to personal data shall be limited only to one that is
          necessary for the proper performance of the Agreement and shall be available only to
          persons authorized and trained in the scope of provisions on the protection of personal
          data.
        </li>
        <li>
          The Processor undertakes to maintain a registry of activities related to the processing of
          personal data. At every request of the Controller, the Processor shall provide the
          registry of personal data processing activities, excluding information constituting
          business secrets of the Processor’s other clients.
        </li>
      </ol>

      <h2>
        §6 <br />
        OBLIGATIONS OF THE CONTROLLER
      </h2>
      <p>
        The Controller undertakes to cooperate and assist the Processor in order to correctly
        implement the provisions of this Agreement and the SaaS Agreement aiming at guaranteeing the
        high level of personal data security and full compliance of the actions taken in accordance
        with the Regulation and other generally applicable laws. The Controller cooperates and
        assists the Processor, in particular for the purpose of implementing the rights of the
        individual set out in Chapter III of the Regulation, and in order to fulfill the obligations
        arising from the area of personal data protection, referred to in art. 32 - 36 of the
        Regulation.
      </p>

      <h2>
        §7 <br />
        PERSONAL DATA SECURITY
      </h2>
      <ol type="1">
        <li>
          The Processor declares that he ensured the high level of security of personal data in view
          of the nature, scope, context and purposes of the processing of personal data.
        </li>
        <li>
          The Processor has carried out an analysis of the risk of the processing of personal data
          that has been entrusted to him (hereinafter referred to as the &quot;
          <strong>Report</strong>
          &quot;). The Processor implemented the appropriate technical and organizational measures
          corresponding to the risk resulting from the Report, taking into account the state of
          technical knowledge and reasonable costs of implementing safeguards.
        </li>
        <li>
          The Processor shall conduct analyzes of the processing of personal data at least once in
          the calendar year. Based on the Report and the resulting level of risk, the Processor
          shall take actions to ensure the high level of data security, taking into account the
          state of technical knowledge and reasonable costs of implementing safeguards.
        </li>
        <li>
          The Processor declares to:
          <ol type="i">
            <li>
              have analyzed the suitability of pseudonymization and encryption and applied these
              techniques insofar as they are needed to ensure the high level of protection of
              personal data;
            </li>
            <li>
              possess the ability to continually ensure the confidentiality, integrity, availability
              and resilience of data processing systems and services;
            </li>
            <li>
              possess the ability to quickly restore the availability of personal data and access to
              them in the event of a physical or technical incident;
            </li>
            <li>
              test regularly, measures and evaluates the effectiveness of technical and
              organizational measures to ensure the security of data processing.
            </li>
          </ol>
        </li>
        <li>
          The Processor declares that he has taken steps to ensure that every natural person acting
          as the entity authorized by the Controller or Processor, who has access to personal data,
          processes them only at the Controller&quot;s request through such organization of the
          system of operation that access to the data being processed is limited only for persons
          authorized and trained in the scope of provisions on the protection of personal data.
        </li>
        <li>
          On demand, the Processor provides the Controller with certificates, declarations as well
          as other documents confirming the high level of security of processing personal data and
          implementation of appropriate technical and organizational measures.
        </li>
      </ol>

      <h2>
        §8 <br />
        NOTIFICATION OF PERSONAL DATA BREACH
      </h2>
      <ol type="1">
        <li>
          The Processor, after finding the breach of personal data protection, without unnecessary
          delay, yet not later than within 24 hours from finding the breach, notifies the Controller
          about the fact of the breach.
        </li>
        <li>
          The Processor is obliged to cooperate with the Controller, in particular to enable the
          Controller to participate in the explanatory activities and informs the Controller of
          their result. If the explanatory actions confirm the breach of personal data protection,
          the Processor immediately notifies the Controller about it, sending all necessary
          documentation. This documentation should at least:
          <ol type="i">
            <li>
              describe the nature of the personal data breach, including, where possible, the
              categories and approximate number of data subjects, and the categories and approximate
              number of entries of personal data affected by the breach,
            </li>
            <li>
              describe the nature of the personal data breach, including, where possible, the
              categories and approximate number of data subjects, and the categories and approximate
              number of entries of personal data affected by the breach;
            </li>
            <li>
              include the name and contact details of the data protection officer or the designation
              of another contact point from which more information shall be obtained;
            </li>
            <li>describe the possible consequences of the breach of personal data protection;</li>
            <li>
              describe measures taken or proposed by the processor to remedy an infringement of
              personal data protection, including, where appropriate, measures to minimize its
              potential adverse effects.
            </li>
          </ol>
        </li>
        <li>
          The processor is obliged to act without undue delay, seeking for the Controller to report
          a breach of personal data protection authority to the supervisory body no later than
          within 72 hours after finding the breach.
        </li>
        <li>
          The Processor shall document any breaches on the protection of personal data, including
          the circumstances of personal data breach, its consequences and the remedial actions
          taken.
        </li>
      </ol>

      <h2>
        §9 <br />
        DECLARATIONS OF THE PARTIES{' '}
      </h2>
      <ol type="1">
        <li>
          The Controller declares that he is authorized to process data to the extent that he
          entrusted processing to the Processor.
        </li>
        <li>
          The Controller declares that he has the knowledge, capabilities, resources and experience
          to correctly perform his obligations under this Agreement.
        </li>
        <li>
          The Processor declares that as part of his business activity he professionally deals with
          the processing of personal data covered by the Agreement and the SaaS Agreement,
          possessing the necessary knowledge, capabilities, resources and experience. In particular,
          the processor possesses the appropriate technical and organizational measures necessary
          for lawful processing of data, thus giving a guarantee of due performance of this
          Agreement.
        </li>
      </ol>

      <h2>
        §10 <br />
        THE CONTROLLER’S RESPONSIBILITY
      </h2>
      <ol type="1">
        <li>
          The Processor is liable for damages caused by the processing of personal data when he has
          not fulfilled his obligations which the Regulation imposes directly on the Processor, or
          when he acted outside the lawful Controller&quot;s instructions or contrary to these
          instructions.
        </li>
        <li>
          The Processor is responsible in particular for actions or omissions that violate the
          guarantee of the high level of data security.
        </li>
      </ol>

      <h2>
        §11 <br />
        DATA PROCESSING PERIOD
      </h2>
      <ol type="1">
        <li>
          The Parties mutually agree that the processing of personal data shall be carried out
          during the period of the SaaS Agreement and special period (hereinafter referred to as:
          the &quot;<strong>Withdrawal period</strong>
          &quot;).
        </li>
        <li>
          This Agreement has been concluded for the duration of the SaaS Agreement, with the
          reservation of the Withdrawal period.
        </li>
        <li>
          The Withdrawal period lasts for 30 days and is counted from the date of termination or
          expiration of the SaaS Agreement. The Parties may agree to change the Withdrawal period.
        </li>
        <li>
          At the moment of termination or expiration of the SaaS Agreement, the Processor is obliged
          to:
          <ol type="i">
            <li>refrain from further data processing, unless it is necessary to delete data;</li>
            <li>
              delete data and prepare and submit to the Controller a written report of deletion of
              data specifying the date and method of deletion of data;
            </li>
            <li>
              delete of all existing copies of personal data, unless the European Union law or
              generally applicable law requires storage of personal data;
            </li>
          </ol>
        </li>
      </ol>

      <h2>
        §12 <br />
        SALVATORY CLAUSE
      </h2>
      <p>
        If the provisions of this Agreement are either invalid or ineffective, or the Agreement
        contains a loophole, it shall not affect the validity and effectiveness of the remaining
        provisions of the Agreement. Instead of invalid or ineffective provisions, or to fill the
        loophole, the appropriate regulation will be binding, which – as long as is legally
        admissible – would be as close as possible to what the Parties have agreed upon or what the
        Parties agreed upon if they would conclude such a provision, under the condition that, the
        entire Agreement without invalid or ineffective provisions preserves reasonable content.
      </p>

      <h2>
        §13 <br />
        FINAL PROVISIONS{' '}
      </h2>
      <ol type="1">
        <li>
          In matters not covered by this Agreement provisions of the Regulation and SaaS Agreement
          shall apply as well as the provisions of generally applicable law on the territory of the
          Republic of Poland.
        </li>
        <li>
          In the event of a crash between the provisions of this Agreement and the provisions of the
          SaaS Agreement, the provisions of this Agreement shall prevail to the extent to which they
          regulate the relationship of entrusting the processing of personal data between the
          Controller and the Processor.{' '}
        </li>
      </ol>
    </section>
  </Layout>
);

export default Tos;
